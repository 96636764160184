import * as Sentry from "@sentry/vue";
import "@vant/touch-emulator";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Store from "store";
import { Dialog } from "vant";
import Vue from "vue";
import router from "./router";
import "./vendor/extends";
import "./vendor/filters";
import { fetchApiUrl, pickUrlByPing } from "./vendor/getUrl";
import "./vendor/mountVant";
import Tools, { checkInIframe,getQueryString } from "./vendor/tools";

import "@/assets/style/global.scss";

import { appInitFailed } from "@/vendor/init";
import { initVue } from "@/vendor/initVue";
import { createMaintenance } from "@/vendor/maintenance";
import { mountVueAtHttp, setAuthentication, setBaseUrl } from "@/vendor/q";
import { checkUpdate } from "./vendor/checkUpdate";
import { initAxios, setDeviceInfo } from "./vendor/q/instances";
// 自动登录从自家平台
const domainSearch = new URLSearchParams(location.search);

window.searchParams = domainSearch;

if (domainSearch.has("t")) {
  Store.set("TOKEN", domainSearch.get("t"));
}

Vue.use(Dialog);

Vue.config.productionTip = false;

Vue.prototype["$tls"] = Tools;
Vue.prototype.$st = Store;
Vue.prototype.Sentry = Sentry;
window.Sentry = Sentry;

let vueInstance;

const INITIAL_TOKEN = Store.get("TOKEN");

if (!INITIAL_TOKEN) {
  Store.remove("USER_INFO");
  Store.remove("TOKEN");
}

window.h5p.hook(async ({ schemaArguments, uuid, appVer: appVer, system, version, model } = {}) => {
  try {
    window.plus &&
      Sentry.captureMessage(
        `user uuid:${uuid} appVer:${appVer} system:${system} version:${version} model:${model}`,
        "info"
      );
    // H5+ 网络请求会替换window原生请求
    initAxios();
    setAuthentication(INITIAL_TOKEN);
    setDeviceInfo({ uuid, system, version, model });

    vueInstance = initVue({
      schemaArguments,
      uuid,
      appVer: appVer,
      system,
      version,
      model,
      INITIAL_TOKEN,
    });

    if (!checkInIframe()) {
      const path = sessionStorage.getItem("RouterPathForPCIframe");
      if (path) await vueInstance.$router.replace(path.replace("#/", "")).catch(() => {});
      sessionStorage.removeItem("RouterPathForPCIframe");
    }

    mountVueAtHttp(vueInstance);

    router.beforeEach((to, from, next) => {
      console.log(from.path, "to", to.path);
      // console.log("to==", to);
      // console.log("from==", from);
      // console.log("searchParams==", this.$route.query.query);
      
      if ( from.path.indexOf("link_account") !== -1 || from.path.indexOf("auto_register") !== -1 || to.path.indexOf("auto_register") !== -1 ) {
        console.log('设置LINK_ACCOUNT_TOKEN==',getQueryString('auth'));
        getQueryString('auth') && window.sessionStorage.setItem("LINK_ACCOUNT_TOKEN",getQueryString('auth'));
      }

      if (vueInstance && !vueInstance.token && !to.meta.noAuth) {
        console.log("无权限，跳转到/layout_auth");
        return next("/layout_auth");
      }

      if (to.path.indexOf("transaction_sale") < 0 && to.path.indexOf("orders") < 0) {
        localStorage.removeItem("ORDERS_FILTERS");
      }

      if (vueInstance.appVersion === "x") {
        return next(false);
      }

      if (
        to.meta.checkSecPwd &&
        vueInstance.userInfo &&
        vueInstance.userInfo.secPasswordStatus === false
      ) {
        vueInstance.$dialog
          .alert({
            className: "common",
            message: "请设置资金密码",
            closeOnClickOverlay: true,
            beforeClose(action, done) {
              if (action === "confirm") {
                vueInstance.$router.push("/user_pay_psw");
              }
              done();
            },
          })
          .catch((e) => {});
        return next(false);
      }

      if (
        from.path.indexOf("user_login_psw") !== -1 &&
        vueInstance.userInfo &&
        vueInstance.userInfo.isEnteredPassword === false
      ) {
        vueInstance.$dialog
          .alert({
            className: "common",
            message: "为了你的账户安全,请设置登录密码",
            closeOnClickOverlay: false,
            beforeClose(action, done) {
              done();
            },
          })
          .catch((e) => {});
        return next(false);
      }

      if (to.meta.identify && vueInstance.userInfo && !vueInstance.userInfo.isRealNameAuth) {
        // 用户名注册
        if (vueInstance.registerMode === "1") {
          if (!vueInstance.isSetUserName) {
            vueInstance.$dialog
              .alert({
                className: "common",
                message: "请设置用户名!",
                beforeClose(action, done) {
                  next("/user_name");
                  done();
                },
              })
              .catch((e) => {});
            return;
          }
        }
        // 手机号码注册
        if (vueInstance.registerMode === "2") {
          if (!vueInstance.isBindPhone) {
            vueInstance.$dialog
              .alert({
                className: "common",
                message: "请绑定手机号!",
                beforeClose(action, done) {
                  next("/user_bind_phone");
                  done();
                },
              })
              .catch((e) => {});
            return;
          }
        }
        vueInstance.globalIdentifyDialogVisibility = true;
        return next(false);
      }

      // if (to.meta.isBindPayment && vueInstance.userInfo && vueInstance.userInfo.paymentType === 0) {
      //   Dialog.alert({
      //     message: "请先绑定支付方式！", className: "common", beforeClose(action, done) {
      //       next('/payments')
      //       done();
      //     }
      //   })
      //   return;
      // }

      if (to.meta.noAuth === true && vueInstance.token) {
        console.log("已登录，页面无需权限");
        return next(false);
      }

      if (vueInstance.token) {
        console.log("已登录");
        if( to.path.indexOf("auto_register") !== -1 ){
          return next("/");
        }
        if (to.meta.noAuth) {
          next(false);
        } else {
          next();
        }
        return;
      }

      if (!vueInstance.token && to.meta.noAuth) {
        console.log("未登录，页面无需权限");
        setTimeout(() => (vueInstance.userInfo = null), 0);
        return next();
      }

      if (!vueInstance.token && !to.meta.noAuth) {
        console.log("未登录，页面需权限");
        return next("/layout_auth");
      }

      next();
    });

    router.afterEach(() => {
      vueInstance.bottomBarDisplay = false;
      vueInstance.title = "";
      vueInstance.$emit("root:h5p:close-kf");
    });

    vueInstance.$mount("#app");

    // 关闭启动页
    if (window.h5p && window.h5p.closeSplash) window.h5p.closeSplash();

    try {
      // 测试套件
      const debug = localStorage.getItem("debug");
      if (debug || process.env.VUE_APP_ENV.indexOf('test') !== -1 ) {
        const script = document.createElement("script");
        script.setAttribute("id", "eruda-script");
        script.type = "text/javascript";
        script.src = "js/eruda.js";
        script.onload = function () {
          window.eruda.init();
        };
        document.body && document.body.append && document.body.append(script);
      }
    } catch (error) {
      console.error("eruda amount error:", error);
    }
    // 请求基本数据
    const data = await fetchApiUrl();

    if (!data) return window.plus ? appInitFailed() : window.alert("初始化失败！");

    const base = JSON.parse(String(data));
    // api 地址选择
    const { url, cfg } = await pickUrlByPing(base.api_url);
    // 系统维护逻辑
    if (cfg.maintenance.status === "1") {
      vueInstance.$destroy();
      createMaintenance(base, cfg);
      return;
    }
    // 判断更新，强制更新时不用初始化vue
    checkUpdate(appVer, cfg.appVersion);
    // 设置http请求地址
    setBaseUrl(url);
    // 等待ua parse 加载完毕，由于所有请求头必须带设备型号
    // await loopIf(() => {
    //   return window.localStorage.getItem("UA") || window.UAParser;
    // });
    // 设置设备信息到请求头
    setDeviceInfo({ uuid, system, version, model });

    vueInstance.config = cfg;
    vueInstance.cdnSniffing(base, cfg);
    vueInstance.waitingSellerCompletesOrderDelayTime =
      cfg.market.waitingSellerCompletesOrderDelayTime;
    vueInstance.waitingSellerConfirmDelayTime = cfg.market.waitingSellerConfirmDelayTime;
    vueInstance.buyerPaysOvertimeDelayTime = cfg.market.buyerPaysOvertimeDelayTime;
    vueInstance.appVersion = cfg.appVersion;
    vueInstance.downloadAddress = cfg.downloadAddress;
    vueInstance.kfHtmlSrc = cfg.kfService;
    vueInstance.verifyCodeType = cfg.verifyCodeType;
    // 商家微信支付宝前台补贴开关
    vueInstance.isMerchantAlipayWechatOpen = cfg.businessAlipayWechatWebStatus === '1'

    // 没有 token
    if (!INITIAL_TOKEN) {
      console.log("没有 token");
      await vueInstance.$router.replace("/layout_auth").catch(() => {});
      // 完成初始化
      console.warn("初始化完成");
      vueInstance.initialized = true;
      return;
    }

    if (domainSearch.has("scheme")) vueInstance.schemaArguments = domainSearch.get("scheme");

    const { ok, msg } = await vueInstance.updateUserInfo();
    if (!ok) {
      console.log("token 失效");
      await vueInstance.$router.replace("/layout_auth").catch(() => {});
      // 完成初始化
      console.warn("初始化完成");
      vueInstance.initialized = true;
      return Sentry.captureException(new Error(msg), {
        tags: {
          capture_fn: "vueInstance.updateUserInfo",
        },
      });
    }

    // 完成初始化
    console.warn("初始化完成");
    vueInstance.initialized = true;

    vueInstance.schemaArguments &&
      (await vueInstance.processQrcodeResult(vueInstance.schemaArguments));

    if (vueInstance.isH5Plus) {
      // 处理唤起传参
      window.h5p.on("arguments", (args) => {
        console.log(`唤起传参:${args} vs:${vueInstance.schemaArguments}`);
        vueInstance.schemaArguments = args;
        vueInstance.processQrcodeResult(args);
      });
    }
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        capture_fn: "hook",
      },
    });
  }
});

import {q} from '@/vendor/q';

export const register = data => q({
  url: '/Api/User/MyAccount/register',
  method: 'POST',
  data
})

export const loginDevice = data => q({
  url: '/Api/User/MyAccount/loginDevice',
  method: 'POST',
  data
})

export const getQRCodeLoginUuid = data => q({
  url: '/Api/Common/QRLogin/getQRCodeLoginUuid',
  method: 'GET',
  data
})

export const QRCodeLoginScan = data => q({
  url: '/Api/User/MyAccount/QRCodeLoginScan',
  method: 'GET',
  data
})

export const QRCodeLoginConfirm = data => q({
  url: '/Api/User/MyAccount/QRCodeLoginConfirm',
  method: 'POST',
  data
})

export const QRCodeLoginCancel = data => q({
  url: '/Api/User/MyAccount/QRCodeLoginCancel',
  method: 'POST',
  data
})

export const checkQRCodeLoginUuid = data => q({
  url: '/Api/Common/QRLogin/checkQRCodeLoginUuid',
  method: 'GET',
  data
})

export const login = data => q({
  url: '/Api/User/MyAccount/login',
  method: 'POST',
  data
})

export const getUserInfo = data => q({
  // url: '/Api/User/Info/getUserInfo',
  url: "/Api/User/MyAccount/userInfo",
  method: 'GET',
  data
})

// export const getBalance = data => q({
//     url: '/Api/User/Info/getBalance',
//     method: 'POST',
//     data
// })

export const editNickName = data => q({
  url: '/Api/User/MyAccount/editNickname',
  method: 'POST',
  data
})

// 绑定用户名
export const editUserName = data => q({
  url: '/Api/User/MyAccount/editUserName',
  method: 'POST',
  data
})

export const addPayment = data => q({
  url: '/Api/User/PaymentType/add',
  method: 'POST',
  data
})

export const getBankInfo = data => q({
  url: '/Api/User/PaymentType/getBankInfo',
  method: 'GET',
  data
})

/**
 * 重置登录密码
 * @param data
 */
export const resetPwd = data => q({
  url: '/Api/User/MyAccount/resetPwd',
  method: 'POST',
  data
})

// 初始化绑定
export const setPwd = data => q({
  url: '/Api/User/MyAccount/setPwd',
  method: 'POST',
  data
})

// 绑定手机号
export const checkBindPhoneNumber = data => q({
  url: '/Api/User/MyAccount/checkBindPhoneNumber',
  method: 'POST',
  data
})

export const resetSecPwd = data => q({
  url: '/Api/User/Account/resetSecPwd',
  method: 'POST',
  data
})

export const setSecPwd = data => q({
  url: '/Api/User/MyAccount/setSecPwd',
  method: 'POST',
  data
})

// 实名认证
export const addRealNameAuth = data => q({
  url: '/Api/User/RealNameAuth/add',
  method: 'POST',
  data
})

// 个人帐变
export const Change = data => q({
  url: '/Api/User/Change',
  method: 'POST',
  data
})

// 获取用户账变类型
export const getChangeType = data => q({
  url: '/Api/User/Change/getChangeType',
  method: 'GET',
  data
})

// 获取用户信用等级信息
export const getCreditInfo = data => q({
  url: '/Api/User/Info/getCreditInfo',
  method: 'GET',
  data
})

// 获取等级信息介绍
export const getCreditLevelConfig = data => q({
  url: '/Api/Common/CreditLevel/getCreditLevelConfig',
  method: 'GET',
  data
})

// 获取收款信息
export const getPaymentType = data => q({
  url: '/Api/User/PaymentType',
  method: 'GET',
  data
})

// 设置用户头像
export const setAvatar = data => q({
  url: '/Api/User/MyAccount/setAvatar',
  method: 'POST',
  data
})

// 获取站内信
export const getMessages = data => q({
  url: '/Api/User/Message/Message/index',
  method: 'GET',
  data
})

// 站内信设置已读
export const setRead = data => q({
  url: '/Api/User/Message/Message/setRead',
  method: 'POST',
  data
})

// 获取消息未读
export const getUnreadCount = data => q({
  url: '/Api/User/Message/Message/getUnreadCount',
  method: 'GET',
  data
})

// 一键已读
export const oneClickRead = data => q({
  url: '/Api/User/Message/Message/oneClickRead',
  method: 'GET',
  data
})

// 一键删除
export const deleteMessages = data => q({
  url: '/Api/User/Message/Message/delete',
  method: 'POST',
  data
})

// 设备列表
export const myDeviceList = data => q({
  url: '/Api/User/Device/myDeviceList',
  method: 'GET',
  data
})

// 删除设备
export const deleteDevice = data => q({
  url: '/Api/User/Device/deleteDevice',
  method: 'POST',
  data
})

// 短信登录
export const smsSignIn = data => q({
  url: '/Api/User/MyAccount/loginSms',
  method: 'POST',
  data
})

// 短信登录
export const unBind = data => q({
  url: '/Api/User/PaymentType/unBind',
  method: 'POST',
  data
})

// 获取google验证的数据
export const bindGoogleAuthenticator = data => q({
  url: '/Api/User/MyAccount/bindGoogleAuthenticator',
  method: 'POST',
  data
})

// 验证google验证码绑定
export const checkBindGoogleAuthenticator = data => q({
  url: '/Api/User/MyAccount/checkBindGoogleAuthenticator',
  method: 'POST',
  data
})

// 解绑google 验证
export const unBindGoogleAuthenticator = data => q({
  url: '/Api/User/MyAccount/unBindGoogleAuthenticator',
  method: 'POST',
  data
})

export const checkGoogleAuthenticator = data => q({
  url: '/Api/User/MyAccount/checkGoogleAuthenticator',
  method: 'POST',
  data
})

export const addUserTransfer = data => q({
  url: '/Api/User/Account/addUserTransfer',
  method: 'POST',
  data
})

export const myChangeDetail = data => q({
  url: '/Api/User/Change/myChangeDetail',
  method: 'POST',
  data
})

// 支付扫码订单
export const payUserDeposit = data => q({
  url: `/Api/User/TransferOrder/userDeposit`,
  method: 'POST',
  data
})

// 绑定钱包
export const QRCodeLoginAuthorize = data => q({
  url: `/Api/User/MyAccount/QRCodeLoginAuthorize`,
  method: 'POST',
  data
})

export const getCarouselChartInfo = data => q({
  url: `/Api/User/Info/getCarouselChartInfo`,
  method: 'GET',
  data
})

export const getCarouselChartDetail = data => q({
  url: `/Api/User/Info/getCarouselChartDetail`,
  method: 'GET',
  data
})

export const checkDevice = data => q({
  url: '/Api/User/info/checkDevice',
  method: 'POST',
  data
})

// 自动注册
export const authorizeRegister = data => q({
  url: `/Api/Common/Home/authorizeRegister`,
  method: 'POST',
  data
})
const tools = Object.create(null);

const GET_TYPE = /^(?:\[object )([A-z]+)(?:])$/;

function _type(val) {
  let match = Object.prototype.toString.call(val).match(GET_TYPE);
  if (match && match.length === 2) {
    return match[1];
  } else {
    console.error(`[参数]:${val},匹配结果:${match}`);
  }
}

tools.__ = () => {};
tools.type = (val) => _type(val);
tools.isElement = (v) => _type(v).indexOf("Element") !== -1;
tools.isArray = (v) => "Array" === _type(v);
tools.isNull = (v) => "Null" === _type(v);
tools.isUndefined = (v) => "Undefined" === _type(v);
tools.isBoolean = (v) => "Boolean" === _type(v);
tools.isString = (v) => "String" === _type(v);
tools.isNumber = (v) => !isNaN(v) && "Number" === _type(v);
tools.isObject = (v) => "Object" === _type(v);
tools.isFunction = (v) => "Function" === _type(v);
tools.isEmpty = (v) => {
  if (tools.isUndefined(v) || tools.isNull(v)) return true;
  if (tools.isArray(v) && v.length === 0) return true;
  if (tools.isObject(v) && Object.keys(v).length === 0) return true;
  return tools.isString(v) && v === "";
};

tools.dateFix = (time) => {
  if (tools.isNumber(time)) return time;
  if (tools.type(time) === "Date") return time;
  if (!tools.isString(time)) {
    console.error("传入时间字符串");
    return new Date().toLocaleDateString();
  }
  // 2020-04-11T16:41:00
  if (time.indexOf("T") > -1) time = time.replace("T", " ");
  // 2020-04-11T16:41:00.452154
  if (time.indexOf(".") > -1) time = time.split(".")[0];
  let [ymd, hms] = time.split(" ");
  ymd = ymd.replace(/-/gm, "/");
  if (hms) {
    return `${ymd} ${hms}`;
  } else {
    return ymd;
  }
};

// 处理对象中的空值
tools.processParams = (obj, opt) => {
  const res = {};
  const opts = (Array.isArray(opt) ? opt : [opt]).reduce((acc, opt) => ({ ...acc, [opt]: 1 }), {});
  Object.keys(obj).forEach((key) => {
    let value = obj[key];
    if (opts["shake"]) {
      if (value === "" || value === undefined || value === null) {
      } else {
        res[key] = value;
      }
    }
    if (opts["float"]) {
      if (tools.isNumber(value)) {
        res[key] = parseFloat(value).toFixed(2);
      }
    }
  });
  return res;
};

// 补零
export function fixZero(val, max = 10) {
  // @ts-ignore
  if (!tools.isNumber(val)) throw new Error("Fn:fixZero val not a Number type");
  if (val < max) {
    const fillLen = String(max).length - String(val).length;
    return "0".repeat(fillLen) + val;
  } else {
    return val;
  }
}

// 解析时间
const parseTime = (date) => {
  const years = date.getFullYear();
  const months = date.getMonth() + 1;
  const days = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
  };
};

// 时间格式化
export function dateFormat(val, formatter = "") {
  const strDate = tools.dateFix(val);
  const date = new Date(strDate);
  if (!formatter) return date;
  // 'Y:M:D h:f:m'
  const { years, months, days, hours, minutes, seconds } = parseTime(date);

  const o = {
    Y: years,
    M: fixZero(Number(months), 10),
    D: fixZero(Number(days), 10),
    h: fixZero(Number(hours), 10),
    m: fixZero(Number(minutes), 10),
    s: fixZero(Number(seconds), 10),
  };
  return formatter.replace(/[YMDhms]/g, function ($1) {
    return o[$1];
  });
}

// 金额格式化
// @ts-ignore
export function amountFormat(number, { places, symbol, thousand, decimal } = {}) {
  number = number || 0;
  places = !isNaN((places = Math.abs(places))) ? places : 2;
  symbol = symbol !== undefined ? symbol : "";
  thousand = thousand || ",";
  decimal = decimal || ".";
  var negative = number < 0 ? "-" : "";
  var i = parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + "";
  var j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    symbol +
    negative +
    (j ? i.substr(0, j) + thousand : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) +
    (places
      ? decimal +
        // @ts-ignore
        Math.abs(number - i)
          .toFixed(places)
          .slice(2)
      : "")
  );
}

// 设置年月日时分秒
export const moment = function (time) {
  if (!(this instanceof moment)) {
    return new moment(time);
  }
  this.time = new Date(tools.dateFix(time || new Date()));
  this.parse = () => {
    const { years, months, days, hours, minutes, seconds } = parseTime(this.time);
    this.parseTime = { years, months, days, hours, minutes, seconds };
  };

  this.year = (num) => {
    this.parse();
    this.time.setFullYear(tools.isNumber(num) ? this.parseTime.years + num : num);
    return this;
  };

  this.month = (num) => {
    this.parse();
    this.time.setMonth(tools.isNumber(num) ? this.parseTime.months - 1 + num : num);
    return this;
  };

  this.day = (num) => {
    this.parse();
    this.time.setDate(tools.isNumber(num) ? this.parseTime.days + num : num);
    return this;
  };

  this.hour = (num) => {
    this.parse();
    this.time.setHours(tools.isNumber(num) ? this.parseTime.hours + num : num);
    return this;
  };

  this.minute = (num) => {
    this.parse();
    this.time.setMinutes(tools.isNumber(num) ? this.parseTime.minutes + num : num);
    return this;
  };

  this.second = (num) => {
    this.parse();
    this.time.setSeconds(tools.isNumber(num) ? this.parseTime.seconds + num : num);
    return this;
  };

  // this.local = () => {
  //     console.log(this.time.toLocaleString());
  // }

  this.format = (...args) => {
    return dateFormat(this.time, ...args);
  };
};

export const formatDuration = (ms) => {
  if (ms < 0) ms = -ms;
  const time = {
    天: Math.floor(ms / 86400000),
    时: Math.floor(ms / 3600000) % 24,
    分: Math.floor(ms / 60000) % 60,
    秒: Math.floor(ms / 1000) % 60,
    // millisecond: Math.floor(ms) % 1000
  };
  return Object.entries(time)
    .filter((val) => val[1] !== 0)
    .map(([key, val]) => `${val} ${key}`)
    .join(", ");
};

export const loopIf = (fn, ms = 100) => {
  return new Promise((resolve, reject) => {
    const loopFn = () => {
      console.log("run loopFn");
      try {
        if (fn()) return resolve();
        else setTimeout(loopFn, ms);
      } catch (error) {
        reject(error);
      }
    };

    loopFn();
  });
};

export const checkPC = () => {
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return false;
  } else {
    if (window.document.body.clientWidth < 768) {
      return false;
    } else {
      return true;
    }
  }
};

export const uaCheckPC = () => {
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return false;
  } else {
    return true;
  }
};


export const checkInIframe = () => {
  return (
    (self.frameElement && self.frameElement.tagName == "IFRAME") ||
    window.frames.length != parent.frames.length ||
    self != top
  );
};

export const getQueryString = (parameterName) => {  
  const path = window.location.href;
  const parameterIndex = path.indexOf(parameterName);
  if (parameterIndex !== -1) {
    const valueStartIndex = parameterIndex + parameterName.length + 1;
    const valueEndIndex = path.indexOf('&', valueStartIndex) !== -1 ? path.indexOf('&', valueStartIndex) : path.length;
    const value = path.substring(valueStartIndex, valueEndIndex);
    return value
  }  
}


export default tools;
